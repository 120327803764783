<template>
  <div class="elv-journal-type-dropdown-content">
    <el-select
      :teleported="false"
      filterable
      v-bind="$attrs"
      popper-class="elv-journal-type-dropdown-popper"
      placeholder="Search"
      clearable
      :filter-method="onFilterMethod"
      @change="onChangeSelect"
      @clear="onClearSelect"
    >
      <template #prefix>
        <SvgIcon name="select-search" width="14" height="14" />
      </template>
      <el-scrollbar
        v-show="filterKeyword === ''"
        wrap-class="elv-journal-type-dropdown-menu-scrollbar__wrap"
        view-class="elv-journal-type-dropdown-menu-scrollbar__view"
      >
        <el-option-group
          v-for="(item, i) in props.dropDownData"
          :key="i"
          class="elv-journal-type-dropdown-menu"
          :label="transformI18n(item.groupName)"
          :class="{
            'is-selected': selectedMenu === item.group,
            'is-general_journal': ['INTERNAL_TRANSFER', 'GENERAL_JOURNAL', 'UNCATEGORIZED'].includes(item.group)
          }"
          @click="onSelectMenu(item.group, $event)"
        >
          <el-checkbox
            v-if="isMultiple"
            v-model="selectedAllList[i]"
            :indeterminate="isCurrentIndeterminate(item.list).value"
            class="elv-journal-type-dropdown-item-checkbox__all"
            :style="{ top: selectedMenu === item.group ? `${i * 32}px` : 0 }"
            @change="onChangeSelectAll(item.list, i)"
          />
          <el-scrollbar
            :height="contentHeight"
            wrap-class="elv-journal-type-dropdown-list-scrollbar__wrap"
            @mouseenter="onMouseEnter"
            @mouseleave="onMouseLeave"
          >
            <template v-if="selectedMenu === item.group">
              <template v-if="isMultiple">
                <el-checkbox-group :model-value="attrs.modelValue">
                  <el-option
                    v-for="children in item.list"
                    :key="children.journalTypeId"
                    class="elv-journal-type-dropdown-item"
                    :label="locale === 'en' ? children.name : children?.nameCN || children.name"
                    :value="children.journalTypeId"
                  >
                    <el-checkbox style="pointer-events: none" :value="children.journalTypeId">{{
                      locale === 'en' ? children.name : children?.nameCN || children.name
                    }}</el-checkbox>
                  </el-option>
                </el-checkbox-group>
              </template>
              <template v-else>
                <el-option
                  v-for="children in item.list"
                  :key="children.journalTypeId"
                  class="elv-journal-type-dropdown-item"
                  :disabled="props.disabledList.includes(children.journalTypeId)"
                  :label="locale === 'en' ? children.name : children?.nameCN || children.name"
                  :value="children.journalTypeId"
                />
              </template>
            </template>
          </el-scrollbar>
        </el-option-group>
      </el-scrollbar>

      <el-option
        v-for="item in journalTypeOption"
        v-show="filterKeyword !== ''"
        :key="item.journalTypeId"
        class="elv-journal-type-dropdown-filter-item"
        :value="item.journalTypeId"
        :label="locale === 'en' ? item.name : item?.nameCN || item.name"
        :disabled="props.disabledList.includes(item.journalTypeId)"
        >{{ locale === 'en' ? item.name : item?.nameCN || item.name }}</el-option
      >
    </el-select>
    <div v-if="showGeneralJournal" class="elv-journal-type-dropdown-general-journal" @click="onCreateGeneralJournal">
      <SvgIcon name="create-project" width="14" height="14" fill="#1753EB" />
      {{ t('button.createGeneralJournal') }}
    </div>
    <div v-if="showJournalBySystem" class="elv-journal-type-dropdown-general-journal">
      <el-checkbox v-model="showSystem">Show the journal created by system</el-checkbox>
      <el-tooltip
        effect="dark"
        placement="top"
        popper-class="elv-report-table-tips"
        overlay-classname="elv-report-table-tips"
        :show-after="500"
      >
        <SvgIcon name="help-filled" width="18" height="18" fill="#D0D4D9" style="margin-left: 6px" />
        <template #content>Including gain/loss adjusting, mark to market, etc</template>
      </el-tooltip>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useI18n } from 'vue-i18n'
import { transformI18n } from '@/i18n/index'
import { find, flatMap, isEmpty } from 'lodash-es'
import { useComputedHook } from '@/hooks/useComputedHook'
import { useTransactionStore } from '@/stores/modules/transactions'

const props = defineProps({
  dropDownData: {
    type: Array<any>,
    required: true
  },
  currentData: {
    type: Object,
    default: () => {}
  },
  showGeneralJournal: {
    type: Boolean,
    default: false
  },
  showJournalBySystem: {
    type: Boolean,
    default: false
  },
  disabledList: {
    type: Array<any>,
    default: []
  }
})
const { t, locale } = useI18n()
const selectedMenu = ref('')
const filterKeyword = ref('')
const attrs: any = useAttrs()
const showSystem = defineModel<boolean>('showSystem')
const selectedAllList = ref<boolean[]>([false])
const transactionStore = useTransactionStore()

const emit = defineEmits(['onChangeJournalTypeSelect', 'onChangeJournalTypeSelectAll', 'onCreateGeneralJournal'])

const isMultiple = computed(() => {
  return Object.hasOwn(attrs, 'multiple')
})

const contentHeight = computed(() => {
  return props.showGeneralJournal || props.showJournalBySystem ? '201px' : '241px'
})

const contentWidth = computed(() => {
  return filterKeyword.value === '' ? '179px' : '100%'
})

const menuPaddingLeft = computed(() => {
  return isMultiple.value ? '34px' : '8px'
})

const showClear = computed(() => {
  return filterKeyword.value !== '' ? 'flex' : 'none'
})

const isCurrentIndeterminate: any = useComputedHook((list: any) => {
  const journalTypeIds = list.map((item: any) => item.journalTypeId)
  // 使用lodash.js判断 journalTypeIds里的值是否都在attrs.modelValue里
  const isAllSelected = journalTypeIds.every((item: any) => attrs.modelValue?.includes(item))
  const isSomeSelected = journalTypeIds.some((item: any) => attrs.modelValue?.includes(item))
  return !isAllSelected && isSomeSelected
})

const journalTypeOption = computed(() => {
  const data = flatMap(props.dropDownData, 'list')
  return data.filter((item: any) => {
    const nameCN = item?.nameCN || item?.name
    return locale.value === 'en'
      ? item?.name?.toLowerCase().includes(filterKeyword.value.toLowerCase())
      : nameCN?.toLowerCase().includes(filterKeyword.value.toLowerCase())
  })
})

const generalJournalTypeId = computed(() => {
  const generalJournal: any = find(transactionStore.journalTypeList, { slug: 'general_journal' })
  return generalJournal?.journalTypeId ?? ''
})

const internalTransferJournalTypeId = computed(() => {
  const internalTransfer: any = find(transactionStore.journalTypeList, { slug: 'internal_transfer' })
  return internalTransfer?.journalTypeId ?? ''
})

const showSelectionStyle = computed(() => {
  let style = 'flex'
  if (isMultiple.value) {
    style = 'none'
  } else if (attrs.modelValue === generalJournalTypeId.value) {
    style = 'none'
  }
  return style
})

const onChangeSelectAll = (list: any, i: number) => {
  const journalTypeIds = list.map((item: any) => item.journalTypeId)
  if (selectedAllList.value[i]) {
    const selectedList = [...attrs.modelValue, ...journalTypeIds]
    emit('onChangeJournalTypeSelectAll', Array.from(new Set(selectedList)))
  } else {
    emit(
      'onChangeJournalTypeSelectAll',
      attrs.modelValue.filter((item: any) => !journalTypeIds.includes(item))
    )
  }
}

const onCreateGeneralJournal = () => {
  emit('onCreateGeneralJournal', generalJournalTypeId.value)
}

const onSelectMenu = (menu: string, e: any) => {
  e.stopPropagation()
  if (['GENERAL_JOURNAL', 'UNCATEGORIZED', 'INTERNAL_TRANSFER'].includes(menu) && isMultiple.value) {
    const journalTypeIndex = props.dropDownData.findIndex((item: any) => item.group === menu)
    if (
      attrs.modelValue.includes(menu === 'GENERAL_JOURNAL' ? generalJournalTypeId.value : '0') ||
      (attrs.modelValue.includes(internalTransferJournalTypeId.value) && menu === 'INTERNAL_TRANSFER')
    ) {
      selectedAllList.value[journalTypeIndex] = e.target?.className === 'el-checkbox__inner'
    } else {
      selectedAllList.value[journalTypeIndex] = e.target?.className !== 'el-checkbox__inner'
    }
    if (e.target?.className !== 'el-checkbox__inner') {
      const journalTypeId = menu === 'GENERAL_JOURNAL' ? generalJournalTypeId.value : '0'
      onChangeSelectAll(
        [{ journalTypeId: menu === 'INTERNAL_TRANSFER' ? internalTransferJournalTypeId.value : journalTypeId }],
        journalTypeIndex
      )
    }
  } else {
    selectedMenu.value = menu
  }
}

const onMouseEnter = () => {
  const verticalScrollbarElement: any = document.querySelector(
    '.elv-journal-type-dropdown-menu-scrollbar__wrap + .el-scrollbar__bar + .is-vertical'
  )
  if (verticalScrollbarElement) {
    verticalScrollbarElement.style.width = '0px'
  }
}

const clearSelectedAllList = () => {
  selectedAllList.value = props.dropDownData.map(() => false)
}

const onMouseLeave = () => {
  const verticalScrollbarElement: any = document.querySelector(
    '.elv-journal-type-dropdown-menu-scrollbar__wrap + .el-scrollbar__bar + .is-vertical'
  )
  if (verticalScrollbarElement) {
    verticalScrollbarElement.style.width = '6px'
  }
}

const onChangeSelect = () => {
  setTimeout(() => {
    if (attrs.modelValue !== '') {
      emit('onChangeJournalTypeSelect')
      if (isMultiple.value) {
        selectedAllList.value = props.dropDownData.map((item: any) => {
          return item.list.every((journalType: any) => {
            return attrs.modelValue.includes(journalType.journalTypeId)
          })
        })
      }
    }
    if (filterKeyword.value !== '' && attrs.modelValue !== '') {
      filterKeyword.value = ''
      if (attrs.modelValue) {
        selectedMenu.value =
          find(transactionStore.journalTypeList, { journalTypeId: attrs.modelValue })?.group ??
          props.dropDownData[0]?.group
      }
    }
  }, 10)
}

const onClearSelect = () => {
  filterKeyword.value = ''
  selectedMenu.value = props.dropDownData[0]?.group
}

const onFilterMethod = (key: string) => {
  filterKeyword.value = key
  return true
}

defineExpose({
  clearSelectedAllList
})

watch(
  () => props.dropDownData,
  () => {
    if (!isEmpty(props.dropDownData)) {
      selectedMenu.value = props.dropDownData[0]?.group
      if (attrs.modelValue) {
        selectedMenu.value =
          find(transactionStore.journalTypeList, { journalTypeId: attrs.modelValue })?.group ??
          props.dropDownData[0]?.group

        if (isMultiple.value) {
          selectedAllList.value = props.dropDownData.map((item: any) => {
            return item.list.every((journalType: any) => {
              return attrs.modelValue.includes(journalType.journalTypeId)
            })
          })
        }
      }
    }
  },
  { immediate: true }
)
</script>

<style lang="scss">
.elv-journal-type-dropdown-content {
  width: 650px;
  height: 280px;

  .el-select {
    .el-select__wrapper {
      height: 39px;
      box-shadow: none !important;
      border: 0px !important;
      border-bottom: 1px solid #dde1e6 !important;

      &.is-filterable.is-hovering .el-select__suffix .el-select__icon {
        display: v-bind('showClear');
      }

      .el-select__selected-item:not(.el-select__input-wrapper) {
        display: v-bind('showSelectionStyle') !important;
      }
    }

    .el-scrollbar__view.el-select-dropdown__list {
      height: v-bind('contentHeight');
      width: v-bind('contentWidth');
    }

    .el-popper.elv-journal-type-dropdown-popper {
      display: block !important;
      background-color: #fff;
      border-radius: 0px;
      border: 0px;
      height: v-bind('contentHeight');
      overflow: hidden;
      box-sizing: border-box;
      box-shadow: none;
      transform: translateY(0px);
      position: unset !important;

      &.el-select-dropdown {
        border-radius: 0;
        height: v-bind('contentHeight');

        :first-child {
          &.is-empty {
            display: none;
          }
        }
      }
    }

    .elv-journal-type-dropdown-filter-item {
      color: #1e2024;
      font-size: 12px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;

      &.is-selected {
        color: #1e2024;
        background-color: #edf0f3;
      }
    }

    .elv-journal-type-dropdown-menu-scrollbar__wrap {
      overflow-x: hidden;
      overflow-y: auto;

      .el-select-group__wrap.elv-journal-type-dropdown-menu {
        flex-direction: row-reverse;
      }

      .elv-journal-type-dropdown-menu-scrollbar__view {
        overflow: hidden;
      }
    }

    .el-select-dropdown__wrap.el-scrollbar__wrap:has(.el-select-dropdown__list) {
      position: relative;
    }

    .elv-journal-type-dropdown-menu {
      display: flex !important;
      border-bottom: 0px;
      width: 179px;
      height: 32px;
      cursor: pointer;

      .el-checkbox.elv-journal-type-dropdown-item-checkbox__all {
        margin-left: 8px;
      }

      &.is-selected {
        background-color: #edf0f3;

        .el-select-group__title {
          padding-left: v-bind('menuPaddingLeft');
        }

        .elv-journal-type-dropdown-item-checkbox__all {
          position: absolute;
          left: -180px;
        }
      }

      &.is-general_journal {
        .el-select-group__title {
          &::after {
            content: none;
          }
        }
      }

      &:not(.is-selected):hover {
        background-color: #f9fafb;
      }

      .elv-journal-type-dropdown-item {
        color: #1e2024;
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;

        &.is-selected {
          color: #1e2024;
          background-color: #edf0f3;
        }
      }

      .el-select-group__title {
        width: 100%;
        border-bottom: 0px;
        position: relative;

        + li:has(.el-select-group):has(.elv-journal-type-dropdown-item) {
          position: fixed;
          top: 0;
          left: 179px;
          width: 471px;
          z-index: 2;
          box-sizing: border-box;
          border-left: 1px solid #edf0f3;
        }

        &::after {
          content: '';
          position: absolute;
          background-image: url('@/assets/img/chevron--right.png');
          background-size: cover;
          right: 8px;
          top: 50%;
          transform: translateY(-50%);
          width: 18px;
          height: 18px;
        }
      }
    }

    .el-select__suffix .el-select__icon {
      display: none;

      &.is-reverse {
        display: none !important;
      }
    }
  }

  .elv-journal-type-dropdown-general-journal {
    display: flex;
    width: 100%;
    height: 40px;
    padding: 12px 8px;
    box-sizing: border-box;
    align-items: center;
    color: #1753eb;
    font-family: 'Plus Jakarta Sans';
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    cursor: pointer;
    border-top: 1px solid #dde1e6;

    svg {
      margin-right: 8px;
    }
  }
}
</style>
